import DebugConsole from '@helper/functions/console';

import {
  flexMicroformAPI,
  invoiceAdminAPI,
  paymentAPI,
  secureAcceptanceAPI,
  unifiedCheckoutAPI,
} from './api';
import { handleErrorMessage } from './shared';

export async function getGenerateCaptureContext(healthEntityCode, paymentType) {
  try {
    let url = `/hppapi/payment/generate-capture-context`
    const { data } = await paymentAPI.get(url, {
      params: {
        healthEntityCode: healthEntityCode,
        paymentType: paymentType,
      },
    });
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
}

export const getGenerateKey = async (healthEntityCode, paymentType) => {
  try {
    let url = `/hppapi/payment/generate-key`
    const { data } = await paymentAPI.get(url, {
      params: {
        healthEntityCode: healthEntityCode,
        paymentType: paymentType,
      },
    });
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

export const postFlexProcessPayment = async (body) => {
  try {
    let data;
    if (
      body.paymentMethod === 'cash' ||
      body.paymentMethod === 'cheque' ||
      body.paymentMethod === 'terminal'
    ) {
      console.log('CASHIER PAYMENT');
      let url = `/invapi/cashier/payment`
      const response = await invoiceAdminAPI(body.healthEntityCode).post(
        url,
        body,
      );
      data = response;
    } else {
      let url = `/hppapi/payment/process`
      const response = await paymentAPI.post(url, body);
      data = response.data;
    }
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

/**
 * Calls backend to initiate Step 1: Setup Payer Auth
 * @see https://developer.cybersource.com/docs/cybs/en-us/payer-authentication/developer/all/rest/payer-auth/pa-ccdc-api-intro/pa-ccdc-setup-service-intro.html
 * @see /model/cybersourceservices/payment/helper/setupPayerAuthentication.js
 */
export const postSetupPayerAuth = async (body) => {
  try {
      let url = `/hppapi/payment/setup-payer-auth`
    const { data }: { data: { data: SetupPayerAuthResponse } } = await paymentAPI.post(
      url,
      body,
    );
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

/**
 * Calls backend to initiate Step 3: Payer Authentication Check Enrollment Service
 * @see https://developer.cybersource.com/docs/cybs/en-us/payer-authentication/developer/all/rest/payer-auth/pa2-ccdc-enroll-intro.html
 * @see /model/cybersourceservices/payment/helper/checkPayerAuthenticationEnrollment.js
 */
export const postCheckPayerAuthEnrollment = async (body: ValidatePayerAuthEnrollmentParam) => {
  try {
    let url = `/hppapi/payment/check-payer-auth-enrollment`
    const { data }: { data: { data: ValidatePayerAuthEnrollmentResponse } } = await paymentAPI.post(
      url,
      body,
    );
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

export const postValidatePayerAuth = async (body) => {
  try {
    let url = `/hppapi/payment/validate-payer-auth`
    const { data }: { data: { data: ValidateAuthResults } } = await paymentAPI.post(
      url,
      body,
    );
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

export const signSecureAcceptanceTransaction = async (formData) => {
  try {
    const { data } = await secureAcceptanceAPI().post(
      `/api/v1/secureAcceptance/signTransaction`,
      formData,
    );
    return data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

export const signSecureAcceptanceHostedTransaction = async (formData) => {
  try {
    const { data } = await secureAcceptanceAPI().post(
      `/api/v1/secureAcceptance/signHostedTransaction`,
      formData,
    );
    return data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

export async function getUnifiedCheckoutCaptureContext(checkoutToken) {
  try {
    const { data } = await unifiedCheckoutAPI().post(
      `/api/v1/unified-checkout/get-authenticated-capture-context`,
      { checkoutToken },
    );
    return { success: true, data };
  } catch (error) {
    handleErrorMessage(error);
    return { success: false, errorMessage: error.toString() };
  }
}

export async function postConfirmPayment(payload) {
  try {
    const { data } = await unifiedCheckoutAPI().post(
      `/api/v1/unified-checkout/confirm-payment`,
      payload,
    );
    return { success: true, data };
  } catch (error) {
    handleErrorMessage(error);
    return { success: false, errorMessage: error.toString() };
  }
}

export async function getMicroformCaptureContext(checkoutToken) {
  try {
    const { data } = await flexMicroformAPI().post('/api/v1/flex-microform/get-capture-context', {
      checkoutToken,
    });

    return { success: true, data };
  } catch (err) {
    handleErrorMessage(err);
    return { success: false, errorMessage: err.toString() };
  }
}

export async function postMicroformSetupPayerAuth(payerAuthPayload, checkoutToken) {
  try {
    const { data } = await flexMicroformAPI().post('/api/v1/flex-microform/setup-payer-auth', {
      ...payerAuthPayload,
      checkoutToken,
    });

    return { success: true, data };
  } catch (err) {
    handleErrorMessage(err);
    return { success: false, errorMessage: err.toString() };
  }
}

export async function postMicroformCheckPayerAuthEnrollment({
  payerAuthPayload,
  checkoutToken,
  referenceId,
  deviceInformation,
}) {
  try {
    const { data } = await flexMicroformAPI().post(
      '/api/v1/flex-microform/check-payer-auth-enrollment',
      {
        ...payerAuthPayload,
        checkoutToken,
        referenceId,
        deviceInformation,
      },
    );

    return { success: true, data };
  } catch (err) {
    handleErrorMessage(err);
    return { success: false, errorMessage: err.toString() };
  }
}

export async function postMicroformValidatePayerAuth({
  transientToken,
  transactionId,
  checkoutToken,
}) {
  try {
    const { data } = await flexMicroformAPI().post('/api/v1/flex-microform/validate-payer-auth', {
      transientToken,
      transactionId,
      checkoutToken,
    });

    return { success: true, data };
  } catch (err) {
    handleErrorMessage(err);
    return { success: false, errorMessage: err.toString() };
  }
}

export async function postMicroformProcessPayment({
  transientToken,
  captureContext,
  checkoutToken,
  payerAuthRes,
}) {
  try {
    const { data } = await flexMicroformAPI().post('/api/v1/flex-microform/process-payment', {
      transientToken,
      captureContext,
      checkoutToken,
      payerAuthRes,
    });

    return { success: true, data };
  } catch (err) {
    handleErrorMessage(err);
    return { success: false, errorMessage: err.toString() };
  }
}

export async function postRefundRequest({ requestToken }) {
  try {
    const { data } = await secureAcceptanceAPI().post(
      '/api/v1/secure-acceptance/refund',
      requestToken,
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      },
    );

    return { success: true, data };
  } catch (err) {
    handleErrorMessage(err);
    return { success: false, errorMessage: err.toString() };
  }
}

export async function postCheckStatus({ requestToken }) {
  try {
    const { data } = await secureAcceptanceAPI().post(
      '/api/v1/secure-acceptance/check-transaction',
      requestToken,
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      },
    );

    return { success: true, data };
  } catch (err) {
    handleErrorMessage(err);
    return { success: false, errorMessage: err.toString() };
  }
}
